import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

interface UsersListStateServiceState {
    searchTerm: string;
    pageIndex: number;
    pageSize: number;
    showClosed: boolean;
    showShareUsers: boolean;
    showOnlyShareUsers: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UsersListStateService {
    private _state = new BehaviorSubject<UsersListStateServiceState>({
        searchTerm: '',
        pageIndex: 0,
        pageSize: 10,
        showClosed: false,
        showShareUsers: false,
        showOnlyShareUsers: false
    });
    get state() {
        return this._state.asObservable();
    }
    updateState(updates: Partial<UsersListStateServiceState>) {
        const currentState = this._state.getValue();
        const newState = { ...currentState, ...updates };
        this._state.next(newState);
    
        // this.router.navigate([], {
        //   relativeTo: this.route,
        //   queryParams: newState,
        //   queryParamsHandling: 'merge'
        // });
      }

    constructor(private router: Router, private route: ActivatedRoute) {
        // this.route.queryParams.subscribe((params) => {
        //     const searchTerm = params['searchTerm'] || '';
        //     const pageIndex = +params['page'] || 1;
        //     this._state.next({ searchTerm, pageIndex });
        // });
    }
}
