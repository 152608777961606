import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ClientOutOfDateComponent } from '../components/error/client-out-of-date/client-out-of-date.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  private clientOutOfDateRef: MatSnackBarRef<ClientOutOfDateComponent>;
  
  constructor(private _snackBar: MatSnackBar) { }

  success(message: string) {
    return this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['notify-success']
    });
  }

  error(message: string) {
    return this._snackBar.open(message, '', {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['notify-error']
    });
  }

  clientOutOfDate() {
    if (this._snackBar._openedSnackBarRef === this.clientOutOfDateRef) return;
    
    const snackBarRef = this._snackBar.openFromComponent(ClientOutOfDateComponent, {
      duration: Infinity,
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: ['notify-error'],
    });

    this.clientOutOfDateRef = snackBarRef;
  }
}
