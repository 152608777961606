import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
    DocumentCaseItemViewModel,
    DocumentDetailsViewModel,
    SecuraMaxApiService,
    ShareViewModel,
} from 'src/app/services/api/securamaxapi.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ShareModifyDialog2Component } from '../share-modify-dialog2/share-modify-dialog2.component';
import { documentStatus } from 'src/app/shared/helpers/document-status-const';
import { ShareService } from 'src/app/services/api2';

interface DocumentDetailsDialogData {
    document: DocumentDetailsViewModel;
    documentStatus: typeof documentStatus;
    isUnlimitedPlanUser: boolean;
    docInOpenCaseWithRetentionList: DocumentCaseItemViewModel[];
}

@Component({
    selector: 'app-share-modify-dialog2',
    templateUrl: './document-details-dialog.component.html',
    styleUrls: ['./document-details-dialog.component.css'],
})
export class DocumentDetailsDialogComponent {
    sharesIsLoading: boolean = true;
    shares: ShareViewModel[] = null;

    constructor(
        private apiService: SecuraMaxApiService,
        private shareService: ShareService,
        private toastr: SnackbarService,
        private cancelShareDialog: MatDialog,
        private dialogRef: MatDialogRef<DocumentDetailsDialogComponent>,
        private shareModifyDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DocumentDetailsDialogData
    ) {
        data.documentStatus = documentStatus;
    }

    ngOnInit() {
        this.apiService
            .share_GetAll(this.data.document.id)
            .toPromise()
            .then(
                (data) => {
                    this.shares = data;
                    this.sharesIsLoading = false;
                },
                () => {
                    this.toastr.error(
                        'An error occurred while getting document shares.'
                    );
                    this.shares = [];
                    this.sharesIsLoading = false;
                }
            );
    }

    openCancelShareDialog(share: ShareViewModel) {
        const dialogRef = this.cancelShareDialog.open(ConfirmDialogComponent, {
            maxWidth: '400px',
            data: {
                title: 'Are you sure?',
                message: `You are about to unshare this document with: ${share.displayName}`,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult) {
                this.shareService.apiApiShareCancelPut(share.id).subscribe(
                    () => {
                        this.toastr.success('Share cancelled.');
                        const index = this.shares.findIndex(
                            (x) => x.id == share.id
                        );
                        if (index != -1) this.shares.splice(index, 1);
                    },
                    (err) => {
                        this.toastr.error(
                            'An error occurred while cancelling the share.'
                        );
                    }
                );
            }
        });
    }

    openModifyShareDialog(share: ShareViewModel) {
        const dialogRef = this.shareModifyDialog.open(ShareModifyDialog2Component, {
            maxWidth: '400px',
            data: {...share}
        });

        dialogRef.afterClosed().subscribe((modifiedShare): void => {
            if (modifiedShare) {
                this.shareService.apiApiSharePut(modifiedShare).subscribe(
                () => {
                    this.toastr.success('Share successfully modified.');
                    const shareInd = this.shares.findIndex(share => share.id == modifiedShare.id);
                    if (shareInd != -1) this.shares[shareInd] = modifiedShare;
                },
                (err) => {
                    this.toastr.error(
                        'An error occured while modifying the share.'
                    )
                })
            }
        })
    }
}
