import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user/user.service';

export interface DialogData {
    id: string;
    name: string;
    associatedUserId: number;
    associatedUser: string;
    deviceType: string;
    alarmEventNetworksEnabled: number[];
    requestNetworksEnabled: number[];
    wasCancelled: boolean;
    liveFeatures: boolean;
}


@Component({
    selector: 'app-devices-modify-dialog',
    templateUrl: './devices-modify-dialog.component.html',
    styleUrls: ['./devices-modify-dialog.component.css'],
})
export class DevicesModifyDialogComponent implements OnInit, OnDestroy {

    problemDescriptions: string[] = [];

    userMode: number = 0;

    automaticTransferMode?: number = 0;

    validationMissingAtLeastOneRequestNetwork: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private dialogRef: MatDialogRef<DevicesModifyDialogComponent>,
        public userService: UserService
    ) {
    }

    ngOnInit(): void {


        // If 'name' passed in was null, we set it to empty string so the 'save' button
        // validation works.
        if (this.data.name == null) this.data.name = '';

        // If associated user is null, the config is in 'Multi-User' mode.
        if (this.data.associatedUserId == null) this.userMode = 1;

        // todo load network settings
        // if no settings, default to enabled on wifi?:
        if (!this.data.alarmEventNetworksEnabled) {
            this.data.alarmEventNetworksEnabled = [0,1];
        }
        
        if (!this.data.requestNetworksEnabled) {
            this.data.requestNetworksEnabled = [0,1];
        }
    }

    ngOnDestroy(): void {
        // Set the associated user to NULL if they set the config to 'Multi-User' mode.
        if (this.userMode == 1) {
            this.data.associatedUserId = null;
            this.data.associatedUser = '';
        }
    }

    validateRequestNetworkSelection(event: Array<number>) {
        if (!event || event.length === 0) {
            this.validationMissingAtLeastOneRequestNetwork = true;
        } else {
            this.validationMissingAtLeastOneRequestNetwork = false;
        }
    }

    tryClose(cancelled: boolean) {
        this.data.wasCancelled = cancelled;
        if (cancelled) {
            this.dialogRef.close(this.data);
        }

        let hasProblems = false;

        this.problemDescriptions.splice(0, this.problemDescriptions.length);

        if (this.validationMissingAtLeastOneRequestNetwork) {
            hasProblems = true;
        }

        if (hasProblems === false) {
            this.dialogRef.close(this.data);
        }
    }

    invalidName(name) {
        const pattern = /^[a-zA-Z0-9 \-_]+$/;
        return name === "" || !pattern.test(name)
    }
}
