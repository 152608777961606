export * from './auditReport.service';
import { AuditReportService } from './auditReport.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './bC400.service';
import { BC400Service } from './bC400.service';
export * from './cases.service';
import { CasesService } from './cases.service';
export * from './categories.service';
import { CategoriesService } from './categories.service';
export * from './device.service';
import { DeviceService } from './device.service';
export * from './deviceConfigurations.service';
import { DeviceConfigurationsService } from './deviceConfigurations.service';
export * from './deviceRegister.service';
import { DeviceRegisterService } from './deviceRegister.service';
export * from './deviceRegisterControllerV2.service';
import { DeviceRegisterControllerV2Service } from './deviceRegisterControllerV2.service';
export * from './deviceRegistration.service';
import { DeviceRegistrationService } from './deviceRegistration.service';
export * from './deviceTypes.service';
import { DeviceTypesService } from './deviceTypes.service';
export * from './devices.service';
import { DevicesService } from './devices.service';
export * from './documentTask.service';
import { DocumentTaskService } from './documentTask.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './email.service';
import { EmailService } from './email.service';
export * from './externalAPI.service';
import { ExternalAPIService } from './externalAPI.service';
export * from './featureFlagsAndAppVersion.service';
import { FeatureFlagsAndAppVersionService } from './featureFlagsAndAppVersion.service';
export * from './fileUpload.service';
import { FileUploadService } from './fileUpload.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './instructions.service';
import { InstructionsService } from './instructions.service';
export * from './liveAuth.service';
import { LiveAuthService } from './liveAuth.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './provision.service';
import { ProvisionService } from './provision.service';
export * from './redaction.service';
import { RedactionService } from './redaction.service';
export * from './request.service';
import { RequestService } from './request.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './share.service';
import { ShareService } from './share.service';
export * from './staticFiles.service';
import { StaticFilesService } from './staticFiles.service';
export * from './tagTypes.service';
import { TagTypesService } from './tagTypes.service';
export * from './tags.service';
import { TagsService } from './tags.service';
export * from './tenant.service';
import { TenantService } from './tenant.service';
export * from './timeBasedTags.service';
import { TimeBasedTagsService } from './timeBasedTags.service';
export * from './timeZone.service';
import { TimeZoneService } from './timeZone.service';
export * from './uploadAuthorize.service';
import { UploadAuthorizeService } from './uploadAuthorize.service';
export * from './uploadLocations.service';
import { UploadLocationsService } from './uploadLocations.service';
export * from './usage.service';
import { UsageService } from './usage.service';
export * from './userBulkRegister.service';
import { UserBulkRegisterService } from './userBulkRegister.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AuditReportService, AuthService, BC400Service, CasesService, CategoriesService, DeviceService, DeviceConfigurationsService, DeviceRegisterService, DeviceRegisterControllerV2Service, DeviceRegistrationService, DeviceTypesService, DevicesService, DocumentTaskService, DocumentsService, EmailService, ExternalAPIService, FeatureFlagsAndAppVersionService, FileUploadService, FilesService, GroupService, InstructionsService, LiveAuthService, MetadataService, ProvisionService, RedactionService, RequestService, SettingsService, ShareService, StaticFilesService, TagTypesService, TagsService, TenantService, TimeBasedTagsService, TimeZoneService, UploadAuthorizeService, UploadLocationsService, UsageService, UserBulkRegisterService, UsersService];
