
<h2 mat-dialog-title i18n>Modify Share</h2>
<mat-label class="m-3" i18n><b>User:</b> {{data.email}}</mat-label>

<div>
    <div class="col">
        <div class="m-3">
            <label i18n>
                <fa-icon icon="calendar"></fa-icon>
                Expires
            </label>
            <br>

            <label>Choose expiration date</label>
            <app-daterangepicker [rangeMode]="false"
                                 (change)="daterangepicker_change($event)"
                                 [(start)]="daterangepicker_start"
                                 [(end)]="daterangepicker_end"></app-daterangepicker>
        </div>
    </div>
    <div class="col-md-4">
        <label i18n>Permissions</label>
        <div class="checkbox">
            <mat-checkbox [checked]="true" [disabled]="true" i18n>View</mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="data.canDownload" i18n>Download</mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="data.canAddMetaData" i18n>AddMetadata</mat-checkbox>
        </div>
        <div class="checkbox">
            <mat-checkbox [(ngModel)]="data.canReshare" i18n>Reshare</mat-checkbox>
        </div>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-button class="smx-critical-button mr-2" mat-dialog-close cdkFocusInitial i18n>Cancel</button>
    <button mat-button class="smx-primary-button" (click)="onClose()" i18n>Save</button>
</mat-dialog-actions>
