<div class="main-container">
    <div class="d-flex justify-content-start mb-4">
        <app-searchbar (search)="onSearch($event)"
                       (clear)="onSearchCleared()"
                       placeholder="User Search"
                       isPrimary="false"
                       [startingText]="state.searchTerm"></app-searchbar>
        <mat-slide-toggle class="ml-3 mt-2"
                          [disabled]="loading"
                          (change)="toggleFilterChanged()"
                          [(ngModel)]="state.showClosed"
                          i18n>
            <label class="mt-3">Show Closed Accounts</label>
        </mat-slide-toggle>

        <mat-slide-toggle class="ml-3 mt-2"
                          [disabled]="loading"
                          (change)="toggleFilterChanged()"
                          [(ngModel)]="state.showShareUsers"
                          i18n>
            <label class="mt-3">Show Share Users</label>
        </mat-slide-toggle>

        <mat-slide-toggle class="ml-3 mt-2"
                          [disabled]="loading"
                          (change)="toggleFilterChanged()"
                          [(ngModel)]="state.showOnlyShareUsers"
                          i18n>
            <label class="mt-3">Show Only Share Users</label>
        </mat-slide-toggle>

        <a mat-button
           routerLink="/admin/users/invite"
           class="smx-primary-button ml-3"
           color="primary"
           i18n>Invite</a>
    </div>

    <mat-card class="details-card">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" i18n>Last</th>
                    <th scope="col" i18n>First</th>
                    <th scope="col" i18n>Email</th>
                    <th scope="col" i18n>Type</th>
                    <th scope="col" i18n>Status</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let user of datasource"
                    [class]="{ active: user.isAccountClosed === true }"
                >
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.email }}</td>
                    <td>
                        <!--<span *ngIf="user.isAD">AD</span>-->
                        <!--<span *ngIf="!user.isAD" class="mr-1" i18n>Local</span>-->
                        <span
                            *ngIf="user.isShareUser === true"
                            class="badge badge-info mr-1"
                            i18n
                            >Share User</span
                        >
                        <span
                            *ngIf="user.redactionSeat === true"
                            class="badge badge-light"
                            title="Redaction License Assigned"
                            i18n-title
                            i18n
                            >R</span
                        >
                    </td>
                    <td>
                        <span
                            class="badge badge-success"
                            *ngIf="
                                user.isAccountVerified && !user.isAccountClosed
                            "
                            i18n
                            >Active</span
                        >
                        <span
                            class="badge badge-warning"
                            *ngIf="
                                !user.isAccountVerified && !user.isAccountClosed
                            "
                            i18n
                        >
                            Not Verified
                        </span>
                        <span
                            class="badge badge-danger"
                            *ngIf="user.isAccountClosed"
                            i18n
                            >Closed</span
                        >
                    </td>
                    <td class="buttons">
                        <a
                            mat-button
                            class="smx-secondary-button"
                            [routerLink]="['../edit', user.iD]"
                            i18n
                            >Edit</a
                        >
                        <ng-container
                            *ngIf="
                                !user.isAccountVerified && !user.isAccountClosed
                            "
                        >
                            <button
                                mat-button
                                class="smx-primary-button ml-2"
                                [matMenuTriggerFor]="menu"
                                aria-label="Resend Verificition Email"
                                i18n
                            >
                                Resend Invite
                            </button>
                            <mat-menu #menu="matMenu">
                                <button
                                    mat-menu-item
                                    (click)="resendVerification(user)"
                                >
                                    <mat-icon>check</mat-icon>
                                    <span i18n>Yes, Resend</span>
                                </button>
                            </mat-menu>
                        </ng-container>
                    </td>
                </tr>
                <tr *ngIf="loading">
                    <td colspan="4">
                        <mat-spinner color="accent"></mat-spinner>
                    </td>
                </tr>
                <tr *ngIf="datasource?.length === 0">
                   <td colspan="4">
                       Search did not match any users.
                   </td>
                </tr>
            </tbody>
        </table>
    </mat-card>

    <app-s-paginate2
        #paginator
        [modelFromJS]="modelFromJS"
        (collection)="onDataSourceUpdate($event)"
        (numItems)="numItemsUpdated($event)"
        (pageChange)="pageChange($event)"
        [url]="'/api/api/Users/All'"
        [urlParams]="urlParams"
        [pageIndex]="state.pageIndex"
        [perPage]="state.pageSize"
    >
    </app-s-paginate2>

    <router-outlet></router-outlet>
</div>
