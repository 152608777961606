import {Injectable} from '@angular/core';

import {
    FeatureFlagsAndAppVersionService
} from 'src/app/services/api2';
import { SnackbarService } from './snackbar.service';


interface FeatureFlags {
  [key: string]: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagStorageService {
    public appVersion: string;
    public featureFlags: FeatureFlags;
    private doneLoading: Promise<void> | null = null;
    private pollingInterval: number = 60000;
    private pollingTimer: any;

    constructor(private featureFlagsService: FeatureFlagsAndAppVersionService, private toastr: SnackbarService) {}

    initialize(): Promise<void> {
        if (!this.doneLoading) {
            this.doneLoading = this.featureFlagsService.apiApiFeatureFlagsAndAppVersionGet().toPromise()
                .then(response => {
                    this.featureFlags = response.FeatureFlags;
                    this.appVersion = response.Version;
                    this.startPolling();
                })
                .catch(error => {
                    console.error('Error fetching feature flags and app version:', error);
                    throw error;
                });
        }
        return this.doneLoading;
    }

    ngOnDestroy() {
        if (this.pollingTimer) {
            clearInterval(this.pollingTimer);
        }
    }

    async isFeatureEnabled(featureName: string): Promise<boolean> {
        if (!this.doneLoading) {
            await this.initialize();
        } else {
            await this.doneLoading;
        }
        let flagValue = !!this.featureFlags[featureName];
        return flagValue;
    }

    async checkFlagsAndVersionAgainstServer() {
        if (this.doneLoading) {
            this.featureFlagsService.apiApiFeatureFlagsAndAppVersionGet().toPromise()
                .then(response => {
                    if (
                        this.appVersion === response.Version && 
                        this.featureFlagSetsAreEqual(this.featureFlags, response.FeatureFlags)
                    ) {
                        return;
                    } else {
                        this.toastr.clientOutOfDate()
                        // console.log(this.featureFlags, response.FeatureFlags)
                        // console.log(this.appVersion, response.Version)
                    }
                })
                .catch(error => {
                    console.error("Error fetching feature flags and app version", error)
                    return;
                })
        }
    }

    startPolling() {
        this.pollingTimer = setInterval(() => {
            this.checkFlagsAndVersionAgainstServer();
        }, this.pollingInterval);
    }

    featureFlagSetsAreEqual(set1: FeatureFlags, set2: FeatureFlags) {
        if(Object.keys(set1).length !== Object.keys(set2).length) {
            return false;
        }

        for (const key in set1) {
            if (!(key in set2) || set1[key] !== set2[key]) {
                return false;
            }
        }

        return true;
    }
}

export function initializeFeatureFlags(featureFlagStorageService: FeatureFlagStorageService) {
    return () => featureFlagStorageService.initialize();
}

