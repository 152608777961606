import { Component } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-client-out-of-date',
  standalone: true,
  imports: [
      MatButtonModule
  ],
  templateUrl: './client-out-of-date.component.html',
  styleUrl: './client-out-of-date.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ClientOutOfDateComponent {

    reloadPage() {
        document.location.reload();
    }
}
